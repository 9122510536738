import React from "react";
import "./seller-account-store-view.scss";

function SellerAccountStoreView() {
  return (
    <>
    <div className="uni_padding d-flex justify-content-between align-items-center mt-3">
      <h1>Store Detail</h1>
    </div>
    </>
  );
}

export default SellerAccountStoreView;
