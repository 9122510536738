import React, { useState } from "react";
import "./seller-account-detail.scss";
import { useParams } from "react-router-dom";
import { apiBaseUrl, imgBaseUrl } from "../../Components/config/config";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import storeAvator from "../../Assets/images/store-logo.png"

function SellerAccountDetail() {
    const [sellerData, setSellerData] = useState({});
    const { storeId } = useParams();
    const { adminToken } = useSelector((state) => state.admin);

    useEffect(() => {
        const config = {
            headers: {
                "x-auth-token": adminToken,
            },
        };
        axios.get(`${apiBaseUrl}/api/admin/seller-detail-info/${storeId}`, config).then((res) => {
            if (res.data.meta.statusCode === 200) {
                setSellerData(res.data.data.store);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeId]);
    //change-store-status
    const changeStatus = (storeId, status, path) => {
        const config = {
            headers: {
                "x-auth-token": adminToken,
            },
        };
        axios.put(`${apiBaseUrl}/api/admin/${path}`, {
                storeId,
                status,
            }, config)
            .then((response) => {
                setSellerData(response.data.data.storeData);
                if(response.data.data.storeData.status === "false") {
                   toast.success("store declined")
                }
      })
            .catch((error) => {
            });
    }

    return (
        <>
            <div className="uni_padding d-flex justify-content-between align-items-center mt-3">
                <h1 className="heading-main">Account Detail</h1>
            </div>
            <div className="dashboard-card">
                <div className="cover-img"></div>
                <div className="pl-3 pr-3">
                    <div className="d-flex user-img-top justify-content-between">
                        <div className="d-flex">
                            {sellerData?.logo ? (
                                <img className="image-size" src={`${imgBaseUrl}/profile/small_${sellerData?.logo}`} alt="" />
                            ) : (
                                <img src={storeAvator} alt="" />
                            )} <div className="ml-2 user_details">
                                <h1>{sellerData?.cardIdentification?.firstAndLastName}</h1>
                                <p>{sellerData?.email}</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-end">
                            <select className="detail-drop" value={sellerData?.status}>
                                <option value="Active">Active</option>
                                <option value="Pending">Pending</option>
                                <option value="Warned">Warning</option>
                                <option value="Blocked">Block</option>
                                <option value="Deleted">Delete</option>
                            </select>
                        </div>
                    </div>

                    <div className="d-flex mt-5">
                        <div className="w-75 address-info">
                            <div className="d-flex flex-wrap">
                                <div className="mb-5">
                                    <p>Store Name:</p>
                                    <b>{sellerData?.storeName}</b>
                                </div>
                                {sellerData && sellerData?.address?.length > 0 ? <>
                                    <div className="mb-5">
                                        <p>Province:</p>
                                        <b>{sellerData?.address[0]?.province}</b>
                                    </div>

                                    <div className="mb-5">
                                        <p>City:</p>
                                        <b>{sellerData?.address[0]?.city}</b>
                                    </div>
                                    <div className="mb-5">
                                        <p>Location:</p>
                                        <b>{sellerData?.address[0]?.location}</b>
                                    </div>
                                </> : <></>}
                                <div className="mb-5">
                                    <p>Phone:</p>
                                    <b>{sellerData?.contact}</b>
                                </div>

                            </div>
                            <hr className="m-0" />
                            {sellerData?.address?.length > 0 ? (
                                <div className="mb-5 mt-4">
                                    <p>Store Address:</p>
                                    {sellerData && sellerData?.address?.map((val, index) => <b key={index}>{val.storeAddress}</b>)}
                                    <div className="d-flex justify-content-end mt-4">
                                        {sellerData.isAddress ? <>
                                            <button className="approve-btn">Approved</button>
                                        </> : <>
                                            <button className="decline-btn" onClick={() => { changeStatus(sellerData._id, false, 'update-store-address-status') }}>Decline</button>
                                            <button className="approve-btn" onClick={() => { changeStatus(sellerData._id, true, 'update-store-address-status') }}>Approve</button>
                                        </>}
                                    </div>
                                </div>) : (<>No Address Data</>)}


                            <hr className="m-0" />
                            {sellerData?.bankAccount?.bankName && sellerData?.bankAccount?.accountNumber ? (
                                <div className="mt-4">

                                    <div className="mb-5">
                                        <p>Bank:</p>
                                        {<b>{sellerData?.bankAccount?.bankName}</b>}
                                        {<p>{sellerData?.bankAccount?.accountNumber}</p>}
                                    </div>

                                    <div className="d-flex justify-content-end mt-4">
                                        {sellerData.isBankVerify ? <>
                                            <button className="approve-btn">Approved</button>
                                        </> : <>
                                            <button className="decline-btn" onClick={() => { changeStatus(sellerData._id, false, 'update-store-bank-status') }}>Decline</button>
                                            <button className="approve-btn" onClick={() => { changeStatus(sellerData._id, true, 'update-store-bank-status') }}>Approve</button>
                                        </>}
                                    </div>
                                </div>) : (<>No Data</>)}
                        </div>
                        <div className="w-25 border cnic-info">
                            <b>ID Card</b>
                            {sellerData?.cardIdentification?.frontImage && sellerData?.cardIdentification?.backImage ? (
                                <>
                                    <div className="mt-4">
                                        <p className="m-0">Front-side:</p>
                                        <img className="cnic-size" src={`${imgBaseUrl}/identity/${sellerData?.cardIdentification?.frontImage}`} alt="" />
                                    </div>
                                    <div className="mt-4">
                                        <p className="m-0">Back-side:</p>
                                        <img className="cnic-size" src={`${imgBaseUrl}/identity/${sellerData?.cardIdentification?.backImage}`} alt="" />
                                    </div>
                                    <div className="d-flex justify-content-end mt-4">
                                        {sellerData.isCardVerify ? <>
                                            <button className="approve-btn">Approved</button>
                                        </> : <>
                                            <button className="decline-btn" onClick={() => { changeStatus(sellerData._id, false, 'update-store-identity-status') }}>Decline</button>
                                            <button className="approve-btn" onClick={() => { changeStatus(sellerData._id, true, 'update-store-identity-status') }}>Approve</button></>}
                                    </div>
                                </>) : (<>No ID card</>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SellerAccountDetail;
