import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Joi from "joi";
import axios from "axios";
import { apiBaseUrl } from "../../Components/config/config";
import { passwordregex } from "../../shared/validators";
import "./verifyToken.scss";
import LoginImage from "../../Assets/images/login-img.svg"
import IconWave from "../../Assets/images/icon-wave.svg"
import Logo from "../../Assets/images/logo-white.svg";
import { useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const VerifyToken = () => {
  const [userPasswordError, setUserPasswordError] = useState(false);
  const [userPasswordErrorMessage, setUserPasswordErrorMessage] = useState("");

  const [userPassword, setUserPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
     useEffect(()=>{
      const queryParams = new URLSearchParams(window.location.search);
      const verifyToken = queryParams.get('verifyToken');
      if(verifyToken){
        axios.post(`${apiBaseUrl}/api/admin/verify-link-token`,{verifyToken}).then((res)=>{
      if(res.data.meta.status) {
            toast(res.data.meta.message); 
           } else {
            toast.error(res.data.meta.message);
            navigate("/");
          }}).catch((error)=>{
            toast.error("Token is not valid");
            navigate("/");
          })} else {
           navigate("/");
         } },[]);

   const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setUserPassword({
      ...userPassword,
      [name]: value,
    });
    setErrors({});
  };

  var schema = Joi.object ({
    newPassword: Joi.string().required().regex(passwordregex).min(8).max(34).label("newPassword").messages({
      "string.empty": "Password cannot be empty",
      "string.pattern.base": "Please enter the correct format for the password",
      "string.min": "Password must be at least 8 characters long",
      "string.max": "Password must be no more than 34 characters long"
    }),
    confirmPassword: Joi.string().required().valid(Joi.ref("newPassword")).messages({
      "any.only": "Passwords do not match",
      "any.required": "Confirm password is required"
    }),
  });

  const formValidation = () => {
    const result = schema.validate(userPassword);
    if (!result.error) return null;

    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formErrors = formValidation();
    if (formErrors) {
      setErrors({ ...formErrors });
    } else {
      setErrors({});
      try {
        const queryParams = new URLSearchParams(window.location.search);
      const verifyToken = queryParams.get('verifyToken');
        const password = userPassword.newPassword;
          axios
          .post(
            `${apiBaseUrl}/api/admin/set-new-password`,
            {password, verifyToken}
          )
          .then((res) => {
            if (res.data.meta.statusCode === 200) {
              setUserPassword({});    
              navigate("/login");
            }
          })
          .catch((err) => {
            if (err.response.data.meta.statusCode === 400) {
              setUserPasswordError(true);
              setUserPasswordErrorMessage(err.response.data.meta.message);
              setTimeout(() => {
                setUserPasswordError(false);
                setUserPasswordErrorMessage("");
              }, 5000);
            }

            if (err.response.data.meta.statusCode === 401) {
              setUserPasswordError(true);
              setUserPasswordErrorMessage(err.response.data.meta.message);
              setTimeout(() => {
                setUserPasswordError(false);
                setUserPasswordErrorMessage("");
              }, 5000);
            }
          });
      } catch (err) {
        return err;
      }
    }
  };

  return (
    <div id="login">
      <div className="girl-image">
       <div className="login-container">
       <Link to="/" className="header-logo">
          <img src={Logo} alt="Logo" />
        </Link>
        <div className="d-flex full-sec flex-xl-row flex-lg-row flex-md-row flex-sm-column  justify-content-between">
        <div className="left-sec">
            <div className="heading-login">Amazing day.</div>
            <div className="description-login mt-3">
              Maybe some text here will help me see it better. Oh God. Oke,
              let’s do it then.
            </div>
          </div>
          <div className="log">
                                   <img
              className="login-image-section"
              src={LoginImage}
              alt="LoginImage"
            />
          </div>
          <div className="mb-5 right-sec form-wid">
          <div className="login-form   mr-5" >
          <img src={IconWave} alt="IconCancel" />
          <div className="mt-2">
                <div className="heading-form">Set your password</div>
              </div>
        <form onSubmit={handleOnSubmit}>
         
          {userPasswordError && (
            <div className="error-text text-center alert-danger" role="alert">
              {userPasswordErrorMessage}
            </div>
          )}
          <div className="mt-2">
          </div>
          <div className="mt-2">
            <div className="field-heading">New Password</div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="********"
                value={userPassword.newPassword || ""}
                name="newPassword"
                onChange={handleInputOnChange}
              />
              {errors.newPassword && (
                <div className="error-text pb-1">{errors.newPassword}</div>
              )}
            </div>
          </div>
          <div className="mt-2">
            <div className="field-heading">Confirm Password</div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="********"
                value={userPassword.confirmPassword || ""}
                name="confirmPassword"
                onChange={handleInputOnChange}
              />
              {errors.confirmPassword && (
                <div className="error-text pb-1">
                  {errors.confirmPassword}
                </div>
              )}
            </div>
          </div>
          <div className="mt-1 mb-3">
            <button type="submit" className="btn-sign-in w-100">
              Save
            </button>
          </div>
        </form>
     
      </div>
      </div>
      </div>
      </div>
      </div>
   
    </div>
  );
};

export default VerifyToken;
