import React from "react";
import HeaderAdmin from "../header-admin/HeaderAdmin";
import SideBarAdmin from "../sidebar-admin/SideBarAdmin"
import "./page-layout-admin.scss"

function PageLayoutAdmin(props) {
  return (
    <div id="wrapper" className="d-flex flex-column">
      <HeaderAdmin />
      <div id="content-wrapper" className="d-flex">
        <SideBarAdmin />
        <div id="content">{props.children}</div>
      </div>
    </div>
  );
}

export default PageLayoutAdmin;
