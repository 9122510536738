import React from "react";
import searchIcon from "../../Assets/images/icon-search.svg"
import refresh from "../../Assets/images/refresh.png";
import userphoto from "../../Assets/images/listing/user-photo.png";

function Reports() {
  
  return (
    <>
       <div className="uni_padding d-flex justify-content-between align-items-center mt-3">
      <h1 className="heading-main">Reports</h1>
    </div>
      <div className="dashboard-card">
      <div className="d-flex justify-content-between">
      <p className="text-dark">Total List: 21 reports</p>
     
      <div className="d-flex search-bar" id="navbarSupportedContent">
      <div type="button" className="search-button text-center m-1">
        <img src={searchIcon} alt="filter icon" /> </div>
       <input className="search-input" placeholder="Search" type="text form-control" />
            </div>  
                </div>

   <div className="card-rap">
   <>
    <div className="review-card mt-3">
      <div className="l-side">
       <div className="d-flex">
       <img className="user-photo" src={userphoto} alt="user-photo" />
       <div className="user-details">
        <h1>Smart Glasses Frame</h1>
        <p>Shop: <span>Zillul Electric Agency</span></p>
        <p>Type: <span>Watch</span></p>
        <p>Product ID: <span>0213254566</span></p>
       </div>
       </div>

      </div>
      <div className="r-side">
     <div className="description mt-2">
      <p className="text-dark">I have reported this product because yhey are doing fraud they send me different product as shown in picture i have attached the pictures too</p>
     </div>
     <div className="mt-3">
    <button className="success-btn">Accept</button>
    <button className="danger-btn">Reject</button>
     </div>
      </div>

    </div>
    <hr />
    </>
    <>
    <div className="review-card mt-3">
      <div className="l-side">
       <div className="d-flex">
       <img className="user-photo" src={userphoto} alt="user-photo" />
       <div className="user-details">
        <h1>Smart Glasses Frame</h1>
        <p>Shop: <span>Zillul Electric Agency</span></p>
        <p>Type: <span>Watch</span></p>
        <p>Product ID: <span>0213254566</span></p>
       </div>
       </div>

      </div>
      <div className="r-side">
     <div className="description mt-2">
      <p className="text-dark">I have reported this product because yhey are doing fraud they send me different product as shown in picture i have attached the pictures too</p>
     </div>
     <div className="mt-3">
    <button className="success-btn">Accept</button>
    <button className="danger-btn">Reject</button>
     </div>
      </div>

    </div>
    <hr />
    </>
    <>
    <div className="review-card mt-3">
      <div className="l-side">
       <div className="d-flex">
       <img className="user-photo" src={userphoto} alt="user-photo" />
       <div className="user-details">
        <h1>Smart Glasses Frame</h1>
        <p>Shop: <span>Zillul Electric Agency</span></p>
        <p>Type: <span>Watch</span></p>
        <p>Product ID: <span>0213254566</span></p>
       </div>
       </div>

      </div>
      <div className="r-side">
     <div className="description mt-2">
      <p className="text-dark">I have reported this product because yhey are doing fraud they send me different product as shown in picture i have attached the pictures too</p>
     </div>
     <div className="mt-3">
    <button className="success-btn">Accept</button>
    <button className="danger-btn">Reject</button>
     </div>
      </div>

    </div>
    <hr />
    </>
    
   </div>
  
      </div>
    </>
  );
}

export default Reports;
