import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiBaseUrl } from "../Components/config/config";

export const adminData = createAsyncThunk(
  "login/admin",
  async (otp, { rejectWithValue }) => {
    try {
      const otpParse = Number(otp);
      const config = {
        headers: {
          "x-auth-otp": sessionStorage.getItem("token"),
        },
      };
      const { data } = await axios.post(
        `${apiBaseUrl}/api/admin/verify-signin-otp`,
        { otp: otpParse },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.meta.message) {
        return rejectWithValue(error.response.data.meta.message);
      } else {
        return rejectWithValue(error.response.data.meta.message);
      }
    }
  }
);
