import React from "react";
// import IconCancel from "../../../Assets/images/icons/icon-cancel.svg";
import IconCancel from "../../../Assets/images/icon-cancel.svg";
import "./confirmModal.scss";

const ConfirmModal = ({ message, setCallApi }) => {
  return (
    <div
      className="modal"
      id="order-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="orderModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="order-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
                <div className="mt-1">
                  <div className="heading-modal">{message}</div>
                </div>

                <div className="mt-1 mb-3 w-100 m-auto d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-cancel"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setCallApi(false)}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn-delete ml-3"
                    onClick={() => setCallApi(true)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
