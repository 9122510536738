import React, { useEffect, useState } from "react";
import "./setting.scss";
import refresh from "../../../Assets/images/user-profile.png"
import InviteAdminModal from "../../../Components/modals/add-admin/InviteAdminModal";
import ChangePasswordModal from "../../../Components/modals/change-password/ChangePasswordModal";
import axios from "axios";
import { useSelector } from "react-redux";
import actions from "../../../Assets/images/icons/actions.svg"
import viewIcon from "../../../Assets/images/icons/view-icon.svg";
import deleteicon from "../../../Assets/images/icons/delete-icon.svg";
import warning from "../../../Assets/images/icons/warning.svg";
import disable from "../../../Assets/images/icons/disable-icon.svg";
import { Link } from "react-router-dom";
import { apiBaseUrl, imgBaseUrl } from "../../../Components/config/config";
import moment from "moment";
import editprofile from "../../../Assets/images/edit-icon.png"
import ChangeProfileModal from "../../../Components/modals/change-profile-modal/ChangeProfileModal";
import $ from "jquery";
import { toast } from "react-toastify";
import ConfirmModal from "../../../Components/modals/confirm-modal/ConfirmModal";

const Setting = () => {
  const [page, setPage] = useState(0);
  const [adminListing, setAdminListing] = useState([]);
  const [callApi, setCallApi] = useState(false);
  const [openModel, setOpenModal] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [status, setStatus] = useState("");
  const {adminToken, adminInfo} = useSelector((state)=> state.admin);

  const confirmChangeStatus = (id, userStatus) => {
    setOpenModal(true);
    setAdminId(id);
    setStatus(userStatus);
  }
 useEffect(() => {
    const config = {
      headers: {
        "x-auth-token": adminToken,
      },
    };
    const apiUrl = `${apiBaseUrl}/api/admin/admin-list?page=${page}`;
    axios.get(apiUrl, config)
      .then((res) => {
        if (res.data.meta.status) {
          setAdminListing(res.data.data.adminList);
        }else{
          setAdminListing(res.data.data.adminList);
        }
      })
      .catch((error) => {
        // Handle error
      });
  }, [page]);
  const handleModalClose = () => {
    $("#order-modal").modal("hide");
  };
  useEffect(() => {
    changeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callApi]);

  const changeStatus = () => {
    const config = {
      headers: {
        "x-auth-token": adminToken,
      },
    };
    if (adminId && status) {
      axios.put(`${apiBaseUrl}/api/admin/update-admin-status`, {
        adminId,
        status,
      }, config)
        .then((res) => {
          if (res.data.meta.status) {
           setCallApi(false);
            handleModalClose();
            setAdminListing((prevUserListing) => {
              const updatedListing = prevUserListing.map((admin) => {
                if (admin._id === res.data?.data?.adminData?._id) {
                  return res.data?.data?.adminData;
                }
                return admin;
              });
              return updatedListing;
            });
          }
        })
        .catch((error) => {
        });
    } else {
      toast.error("your Id is missing")
    }
  }
  return (
    <>
      <div className="uni_padding d-flex justify-content-between align-items-center mt-3">
        <h1 className="heading-main">Admin Settings</h1>
      </div>
      <div className="dashboard-card">
        <div className="cover-img"></div>
        <div className="pl-3 pr-3">
          
          <div className="d-flex user-img-top">
             <div
                  role="button"
                  className="button-status-cancel"
                  data-toggle="modal"
                  data-target="#profile-modal"
                >
                  <img src={editprofile} alt="" /> 
                </div>
            {adminInfo.image?
            
            <div>
              <img src={`${imgBaseUrl}/profile/avatar_${adminInfo.image}`}  className="profile-size" alt="" />
            </div>:<div>
              <img src={refresh} alt="" />
            </div>}

            <div className="d-flex justify-content-between w-100 align-items-end">
              <div className="ml-2 user_details">
                <h1>{adminInfo.name}</h1>
              <p>{adminInfo.email}</p>
              </div>
              <div>
                <button
                  className="btn btn-outline-warning text-dark"
                  role="button"
                  data-toggle="modal"
                  data-target="#password-modal"
                >
                  Change Password
                </button>
                <button className="approve-btn btn"
                  role="button"
                  data-toggle="modal"
                  data-target="#email-modal">Add Admin</button>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <p>Admin List</p>
            <table className="table table-hover mt-3">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Added by</th>
                  <th>Added Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {adminListing.map((val, index)=>{
                return <tr key={index}>
                <td>{index+1}</td>
                <td>
                  <div className="d-flex">
                    <div>
                      <img className="table_img" src={`${imgBaseUrl}/profile/avatar_${val.image}`} alt="" />
                    </div>
                    <div className="ml-2">
                      <p className="m-0 text-dark">{val.name}</p>
                      <p className="m-0">{val.role}</p>
                    </div>
                  </div>
                </td>
                <td>{val.email}</td>
                <td>{val.invitedFrom}</td>
                <td>{moment(val.invitedAt).format(
                                "DD  MMMM  YYYY" 
                              )}{" "}</td>
                <td>
                <div class="dropdown">
                      <img src={actions} alt="Actions" type="button" data-toggle="dropdown" aria-expanded="false" />
                      <div class="dropdown-menu">
                        <ul>
                          {/* <Link to={`/user-account/${val._id}`}> */}
                          <Link style = {{"text-decoration":"none"}}>
                            <li>
                              <div className="ml-2">
                                <img src={viewIcon} alt="View" />
                                <span className="ml-2 text-decoration">View</span>
                              </div>
                            </li>
                          </Link>
                          {val.status === "Warned" ? (<></>) : (<>
                            <li>
                              <div className="ml-2 mt-2" onClick={() => confirmChangeStatus(val._id, "Warned")} data-toggle="modal" data-target="#order-modal">
                                <img src={warning} alt="Warning" />
                                <span className="ml-2 text-decoration">Warning</span>
                              </div>

                            </li>
                          </>)}
                          {val.status === "Blocked" ? (<></>) : (<>
                            <li>
                              <div className="ml-2 mt-2" onClick={() => confirmChangeStatus(val._id, "Blocked")} data-toggle="modal" data-target="#order-modal">
                                <img src={disable} alt="Disable" />
                                <span className="ml-2 text-decoration">Block</span>
                              </div>
                            </li>
                          </>)}
                          {val.status === "Deleted" ? (<></>) : (<>
                            <li>
                              <div className="ml-2 mt-2" onClick={() => confirmChangeStatus(val._id, "Deleted")} data-toggle="modal" data-target="#order-modal">
                                <img src={deleteicon} alt="Delete" />
                                <span className="ml-2 text-decoration">Delete</span>
                              </div>
                            </li>
                          </>)}
                          {val.status === "Active" ? (<></>) : (<>
                            <li>
                              <div className="ml-2 mt-2" onClick={() => confirmChangeStatus(val._id, "Active")} data-toggle="modal" data-target="#order-modal">
                                <img src={deleteicon} alt="Active" />
                                <span className="ml-2 text-decoration">Active</span>
                              </div>
                            </li>
                          </>)}
                        </ul>
                      </div>
                    </div>
                </td>
              </tr>
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
            <p>Showing <span>1</span> to <span>10</span> of <span>198</span> entries</p>
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                {[1, 2, 3, 4, 5]?.map((val, index) => {
                  return (page === index ? <li class="page-item active" onClick={() => setPage(index)}><a class="page-link" href="#">{index + 1}</a></li> : <li class="page-item" onClick={() => setPage(index)}><a class="page-link" href="#">{index + 1}</a></li>)
                })}
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          </div>
        </div>

      </div>
      {openModel && (
        <ConfirmModal
          message={
            status === "Deleted"
              ? "Are you sure you want to delete?"
              : status === "Warned"
                ? "Are you sure you want to warn?"
                : status === "Blocked"
                  ? "Are you sure you want to block?"
                  : status === "Active"
                    ? "Are you sure you want to Active"
                    : ""
          }
          setCallApi={setCallApi}
        />
      )}
      <ChangePasswordModal />
      <InviteAdminModal />
      <ChangeProfileModal />
    </>
  )
}
export default Setting;