import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import LoginImage from "../../Assets/images/login-img.svg";
import IconWave from "../../Assets/images/icon-wave.svg";
import Logo from "../../Assets/images/logo-white.svg";
import "./otp.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { clearOtpStorage } from "../../shared/clearOtpStorage";
import { adminData } from "../../Redux/AdminAction";
import { apiBaseUrl } from "../../Components/config/config";

function Otp() {
  let navigate = useNavigate();
  const { loading, adminInfo, error, adminToken } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [OTP, setOTP] = useState("");
  
  useEffect(() => {
    if (error) {
      setOTP("");
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    } else if (adminInfo) {
      navigate("/dashboard");
       clearOtpStorage();
    }
  }, [navigate, adminInfo, error]);
  const handleChange = (otp) => {
    setOTP(otp);
  };
 const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(adminData(OTP));
  };
  const BackToLogin = () => {
    navigate("/login");
  };

  const resendOtp = () => {
    let user;
    user = {
      email: sessionStorage.getItem('email'),
      password: sessionStorage.getItem('password'),
    }
    axios
        .post(`${apiBaseUrl}/api/admin/signin`,user)
        .then((res) => {
          if (res.data.meta.statusCode === 200) {
            sessionStorage.setItem("token", res.data.data.token);
            sessionStorage.setItem("email", res.data.data.email);
            sessionStorage.setItem("password", res.data.data.password);
            toast(`OTP resend successfully!`);
          }
        })
        .catch((err) => {
          if (err.response.data.meta.statusCode === 400) {
            toast(err.response.data.meta.message);
            navigate("/login");
          }
  
          if (err.response.data.meta.statusCode === 401) {
            toast(err.response.data.meta.message);
            navigate("/login");
          }
        });
  };


  return (
    <div id="forgetpassword">
      <div className="girl-image">
        <div className="login-container">
          <Link to="/">
            <div className="header-logo">
              <img src={Logo} alt="Logo" />
            </div>
          </Link>
          <div className="d-flex full-sec flex-xl-row flex-lg-row flex-md-row flex-sm-column  justify-content-between">
            <div className="left-sec">
              <div className="heading-login">Amazing day.</div>
              <div className="description-login mt-3">
                Maybe some text here will help me see it better. Oh God. Oke,
                let’s do it then.
              </div>
            </div>
            <div className="log">
              <img
                className="login-image-section"
                src={LoginImage}
                alt="LoginImage"
              />
            </div>
            <div className="mb-5 right-sec form-wid">
              <div className="login-form mr-5">
                <img src={IconWave} alt="IconCancel" />
                <div className="mt-2">
                  <div className="heading-form">Enter OTP!</div>
                  <div className="text-para mt-2">
                    Please Enter OTP Sent to Email or Phone Number
                  </div>
                  {error && (
                  <div
                    className="error-text text-center alert-danger"
                    role="alert"
                  >
                    {errorMessage}
                  </div>
                )}
                </div>
                <form onSubmit={handleOnSubmit}>
                  <div className="py-2 mt-2">
                    <div className="field-heading">OTP</div>
                    <div className="mt-1 ">
                    <OtpInput
                      onChange={handleChange}
                      value={OTP}
                      inputStyle="otpinputStyle"
                      numInputs={6}
                      separator={<span></span>}
                    />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-2 mb-3">
                    <div className="otp-resend">Didn't receive OTP</div>
                    <div
                      role="button"
                      className="otp-resend-link"
                      onClick={resendOtp}
                    >
                      Resend
                    </div>
                  </div>
                  <div className="py-2">
                    <button type="submit" disabled={loading} className="btn-sign-in w-100">
                    {loading ? "...loading" : "Verify"}
                    </button>

                  </div>
                </form>
                <div className="mt-5">
                  <div className="login-footer mx-3">
                    Back to{" "}
                    <span
                      role="button"
                      className="login-footer-span"
                      onClick={BackToLogin}
                    >
                      Login
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;
