import React from "react";
import { Link } from "react-router-dom";
import dashboard from "../../Assets/images/icons/sidebar/dashboard-icon.png";
import listing from "../../Assets/images/icons/sidebar/listing-review.png";
import seller from "../../Assets/images/icons/sidebar/sellers-account.png";
import user from "../../Assets/images/icons/sidebar/user-account.png";
import reviews from "../../Assets/images/icons/sidebar/reviews.png";
import report from "../../Assets/images/icons/sidebar/report.png";
import logouts from "../../Assets/images/icons/sidebar/logout.png";
import personicon from "../../Assets/images/icons/sidebar/user-icon.png";
import settings from "../../Assets/images/icons/sidebar/setting.png";
import { imgBaseUrl } from "../config/config";

import "./sidebar-admin.scss"
import ringbell from "../../Assets/images/ringbell.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/AdminSlice";
import ListingReview from "../../Pages/listing-review/ListingReview";

function SideBarAdmin() {
  let pathname = window.location.pathname;
  const {adminInfo}= useSelector((state)=>state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    dispatch(logout());
     navigate("/login");
  };
  return (
    <>
    <ul className="navbar-nav sidebar" id="accordionSidebar">
      <hr className="m-0" />
    <div className="d-flex flex-column justify-content-between bar_height pt-3">
    <div>
     <li className={`${pathname.match("/dashboard")? "sidebar-span-active" : "nav-item"}`}>
        <Link className="nav-link" to="/dashboard" >
        <>
          <img className="mr-3" src={dashboard} alt="iconDashboard" />
           </>
          <span className="sidebar-span">
            Dashboard
          </span>
        </Link>
      </li>

      
      <li className={`${pathname.match("/listing-review")? "sidebar-span-active" : "nav-item"}`}>
        <Link className="nav-link" to="/listing-review" >
         <>
              <img className="mr-3" src={listing } alt="iconMyorder" />
            </>
          <span className="sidebar-span"> Listing </span>
        </Link>
      </li>


      <li className={`${pathname.match("/seller-accounts")? "sidebar-span-active" : "nav-item"}`}>
        <Link className="nav-link" to="/seller-accounts" >
        <>
           <img  className="mr-3" src={seller} alt="iconAddressbook"/>
            </>
          <span className="sidebar-span"> Seller Accounts</span>
        </Link>
      </li>
      
      <li className={`${pathname.match("/user-accounts")? "sidebar-span-active" : "nav-item"}`}>
        <Link className="nav-link" to="/user-accounts">
        <>
           <img className="mr-3" src={user} alt="iconMyfavourite"/>
            </>
          <span className="sidebar-span"> User Accounts</span>
        </Link>
      </li>


      <li className={`${pathname.match("/reviews")? "sidebar-span-active" : "nav-item"}`}>
        <Link className="nav-link" to="/reviews">
          <>
              <img className="mr-3" src={reviews} alt="iconSetting" />
            </>
          <span className="sidebar-span"> Reviews</span>
        </Link>
      </li>

      <li className={`${pathname.match("/reports")? "sidebar-span-active" : "nav-item"}`}>
        <Link className="nav-link" to="/reports">
        <>
           <img className="mr-3" src={report} alt="iconSetting" />
            </>
          <span className="sidebar-span"> Reports</span>
        </Link>
      </li>
      <li className={`${pathname.match("/config")? "sidebar-span-active" : "nav-item"}`}>
        <Link className="nav-link" to="/config">
        <>
           <img className="mr-3" src={report} alt="iconSetting" />
            </>
          <span className="sidebar-span">Config</span>
        </Link>
      </li>
     </div>
      { <ul>
      <li className={`${pathname.match("/setting")? "sidebar-span-active" : "nav-item"}`}>
        <Link className="nav-link mb-2"to="/setting">
      <>
       <img className="mr-3" src={settings} alt="iconSetting" />
            </>
          <span className="sidebar-span"> Settings </span>
        </Link>
      </li>

      <li className="nav-item">
        <div className="nav-link logout-active">
        <img className="mr-3" src={logouts} alt="iconSetting" onClick={logOut}/>
          <span className="sidebar-span text-danger" onClick={logOut}> Logout</span>
          </div>
      </li>
      <hr />
      <li className="nav-item mt-4 p-3">
       
          <div className="d-flex justify-content-between">
           <div className="d-flex">
            
           <div className="mr-2">
            {adminInfo.image === null?(
            <>
             <img className="" width={50} src={personicon} alt="iconSetting" />
            </>
             ) : (
              <>
               <img className="sidebar-image"  src= {`${imgBaseUrl}/profile/avatar_${adminInfo.image}`} />
              </>
            )} 
             </div>
            
            <div className="">
          <p className= "m-0"><b>{adminInfo.name}</b></p>
          <p className= "m-0">Product designer </p>
          </div>
           </div>
           <div className="d-flex align-items-center">
           <img className="" width={25} src={ringbell} alt="iconSetting" />
           </div>

          </div>
      </li>
   
      </ul>}
    </div>
    </ul>
    
   
   
    </>
  
  );
}

export default SideBarAdmin;
