import React, { useState } from "react";
import IconCancel from "../../../Assets/images/icon-cancel.svg"
import { useSelector,} from "react-redux";
import Joi from "joi";
import axios from "axios";
import $ from "jquery";
import { apiBaseUrl } from "../../config/config";
import { emailRegex } from "../../../shared/validators";
import "./inviteAdminModal.scss";
const InviteAdminModal = () => {
  const { adminToken} = useSelector((state) => state.admin);

  const [userEmail, setUserEmail] = useState("");
  const [errors, setErrors] = useState({
    userEmail: "",
  });
  const [userEmailError, setuserEmailError] = useState(false);
  const [userEmailErrorMessage, setUserEmailErrorMessage] = useState("");
  const handleModalClose = () => {
    $("#email-modal").modal("hide");
  };

  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setUserEmail({
      ...userEmail,
      [name]: value,
    });
    setErrors({});
  };
  var schema = Joi.object({
    userEmail: Joi.string().regex(emailRegex).required().label("userEmail").messages({
      "string.empty": "Email cannot be empty",
      "string.pattern.base": "Please enter valid Email"
    }),
  });
  const formValidation = () => {
    const result = schema.validate(userEmail);
    if (!result.error) return null;
    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formErrors = formValidation();
    if (formErrors) {
      setErrors({ ...formErrors });
    } else {
      setErrors({});
      submitUserEmail(userEmail.userEmail);
    }
  };

  const submitUserEmail = (email) => {
    const config = {
      headers: {
        "x-auth-token": adminToken,
      },
    };
    axios
      .post(`${apiBaseUrl}/api/admin/invite`, { email: email }, config)
      .then((res) => {
        if (res.data.meta.statusCode === 200) {
          handleModalClose();
          setUserEmail({});
        }
      })
      .catch((err) => {
        if (err.response.data.meta.statusCode === 400) {
          setuserEmailError(true);
          setUserEmailErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setuserEmailError(false);
            setUserEmailErrorMessage("");
          }, 5000);
        }
        if (err.response.data.meta.statusCode === 401) {
          setuserEmailError(true);
          setUserEmailErrorMessage(err.response.data.meta.message);
          setTimeout(() => {
            setuserEmailError(false);
            setUserEmailErrorMessage("");
          }, 5000);
        }
      });
  };

 

  return (
    <div
      className="modal"
      id="email-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="emailModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="email-modal">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={IconCancel} alt="IconCancel" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body-modal">
               <form onSubmit={handleOnSubmit}>
                      <div className="mt-1">
                        <div className="heading-modal">Invite Admin!</div>
                      </div>
                      {userEmailError && (
                        <div
                          className="error-text text-center alert-danger"
                          role="alert"
                        >
                          {userEmailErrorMessage}
                        </div>
                      )}
                      <div className="mt-3">
                        <div className="field-heading">Email</div>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            value={userEmail.userEmail}
                            name="userEmail"
                            onChange={handleInputOnChange}
                          />
                          {errors.userEmail && (
                            <div className="error-text">{errors.userEmail}</div>
                          )}
                        </div>
                      </div>
                      <div className="mt-1 mb-3">
                        <button type="submit" className="btn-sign-in w-100">
                          Invite
                        </button>
                      </div>
                    </form>
                  
                
                
                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteAdminModal;
