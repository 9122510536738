import React, { useState, useEffect } from "react";
import searchIcon from "../../Assets/images/icon-search.svg"
import refresh from "../../Assets/images/refresh.png";
import userphoto from "../../Assets/images/listing/user-photo.png";
import { apiBaseUrl } from "../../Components/config/config";
import { useSelector } from "react-redux";
import axios from "axios";
import $ from "jquery";
import ConfirmModal from "../../Components/modals/confirm-modal/ConfirmModal";
import { toast } from "react-toastify";
function Reviews() {
  const [reviewsListing, setReviewsListing] = useState("");
  const [page, setPage] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [loadData, setLoadData] = useState(false)
  const [reviewId, setReviewId] = useState("");
  const [status, setStatus] = useState("");
  const { adminToken } = useSelector((state) => state.admin);



  const confirmChangeStatus = (id, listingStatus) => {
    setOpenModal(true);
    setReviewId(id);
    setStatus(listingStatus);
  }
  const handleModalClose = () => {
    $("#order-modal").modal("hide");
  };
  useEffect(() => {
    changeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callApi]);
  const changeStatus = () => {
    const config = {
      headers: {
        "x-auth-token": adminToken,
      },
    };
    if (reviewId && status) {
      axios.put(`${apiBaseUrl}/api/admin/update-listing-status`, {
        reviewId,
        status,
      }, config)
        .then((res) => {
          if (res.data.meta.status) {
            const updatedData = reviewsListing.filter(data =>
              data?.itemReviews._id !== res.data.data?.listing._id
            )
            setReviewsListing(updatedData);
            handleModalClose();
          }
        })
        .catch((error) => {
        });
    } else {
      toast.error("your Id is missing")
    }
  }
  useEffect(() => {
    const config = {
      headers: {
        "x-auth-token": adminToken,
      },
    };

    const apiUrl = `${apiBaseUrl}/api/admin/review-list?page=${page}`;
    axios.get(apiUrl, config)
      .then((res) => {
        if (res.data.meta.status) {
          setReviewsListing(res.data.data.reviewList);
          console.log("data", res.data.data.reviewList

          )
        }
        if (res.data.meta.page === page) {
          setIsLoad(false);
        } else {
          setPage(res.data.meta.page);
        }
      })
      .catch((error) => {
        // Handle error
      });
  }, [page]);

  return (
    <>
      <div className="uni_padding d-flex justify-content-between align-items-center mt-3">
        <h1 className="heading-main">Reviews</h1>
      </div>
      {reviewsListing && reviewsListing?.map((reviews) => (
        <div className="dashboard-card">
          <div className="d-flex justify-content-between">
            <p className="text-dark">Total List: 21 new</p>

            <div className="d-flex search-bar" id="navbarSupportedContent">
              <div type="button" className="search-button text-center m-1">
                <img src={searchIcon} alt="filter icon" /> </div>
              <input className="search-input" placeholder="Search" type="text form-control" />
            </div>
          </div>

          <div className="card-rap">
            <>
              <div className="review-card mt-3">
                <div className="l-side">
                  <div className="d-flex">
                    <img className="user-photo" src={userphoto} alt="user-photo" />
                    <div className="user-details">
                      <h1>PULSE Earbuds</h1>
                      <p>Price: <span>$200</span></p>
                      <p>Color: <span>IVORY WHITE</span></p>
                      <p>Size: <span>xLarge</span></p>
                    </div>
                  </div>

                </div>
                <div className="r-side">

                  <div className="m-0 user-details d-flex flex-wrap">
                    <b>***** star rating</b>
                    <p className="m-0 ml-2">4-24-2024</p>
                  </div>

                  <div className="description mt-2">
                    <p className="text-dark">{reviews.message}</p>
                  </div>
                  <div className="review-imgs">
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                  </div>
                  <div className="mt-3">
                    <button className="success-btn" data-toggle="modal"
                      data-target="#listing-modal" onClick={() => confirmChangeStatus(reviews?._id, "Accept")}> Accept</button>
                    <button className="danger-btn">Reject</button>
                  </div>
                </div>

              </div>
              <hr />
            </>
            <>
              <div className="review-card mt-3">
                <div className="l-side">
                  <div className="d-flex">
                    <img className="user-photo" src={userphoto} alt="user-photo" />
                    <div className="user-details">
                      <h1>PULSE Earbuds</h1>
                      <p>Price: <span>$200</span></p>
                      <p>Color: <span>IVORY WHITE</span></p>
                      <p>Size: <span>xLarge</span></p>
                    </div>
                  </div>

                </div>
                <div className="r-side">

                  <div className="m-0 user-details d-flex flex-wrap">
                    <b>***** star rating</b>
                    <p className="m-0 ml-2">4-24-2024</p>
                  </div>

                  <div className="description mt-2">
                    <p className="text-dark">Delivery time was almost a week but it was worth the wait. Product is most certainly worth the price. I'm surprised by the bass and how well the ENC works thanks to the extra Air tips provided with the product. Battery life is amazing as well. In my humble opinion if you're someone who loves bass, good battery life and earbuds.</p>
                  </div>
                  <div className="review-imgs">
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                  </div>
                  <div className="mt-3">
                    <button className="success-btn"
                    >Accept</button>
                    <button className="danger-btn">Reject</button>
                  </div>
                </div>

              </div>
              <hr />
            </>
            <>
              <div className="review-card mt-3">
                <div className="l-side">
                  <div className="d-flex">
                    <img className="user-photo" src={userphoto} alt="user-photo" />
                    <div className="user-details">
                      <h1>PULSE Earbuds</h1>
                      <p>Price: <span>$200</span></p>
                      <p>Color: <span>IVORY WHITE</span></p>
                      <p>Size: <span>xLarge</span></p>
                    </div>
                  </div>

                </div>
                <div className="r-side">

                  <div className="m-0 user-details d-flex flex-wrap">
                    <b>***** star rating</b>
                    <p className="m-0 ml-2">4-24-2024</p>
                  </div>

                  <div className="description mt-2">
                    <p className="text-dark">Delivery time was almost a week but it was worth the wait. Product is most certainly worth the price. I'm surprised by the bass and how well the ENC works thanks to the extra Air tips provided with the product. Battery life is amazing as well. In my humble opinion if you're someone who loves bass, good battery life and earbuds.</p>
                  </div>
                  <div className="review-imgs">
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                    <img className="user-photo mr-3" src={userphoto} alt="user-photo" />
                  </div>
                  <div className="mt-3">
                    <button className="success-btn">Accept</button>
                    <button className="danger-btn">Reject</button>
                  </div>
                </div>

              </div>
              <hr />
            </>
          </div>
        </div>
      ))}
      {openModal && (
        <ConfirmModal
          message={status === "Accept" ? "Are you sure you want to accept?"
            : status === "Reject" ? "Are you sure you want to reject?"
              : ""}
          setCallApi={setCallApi}
        />
      )}
    </>
  );
}

export default Reviews;
