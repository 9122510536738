import { createSlice } from "@reduxjs/toolkit";
import {adminData} from "./AdminAction";

const adminToken = sessionStorage.getItem("token")
?sessionStorage.getItem("token")
:null;

const initialState = {
    loading: false,
    adminInfo: null,
    adminToken,
    error: null,
    success: false,
}
const AdminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
   logout:(state)=>{
     state.loading = false;
     state.adminInfo = null;
     state.adminToken = null;
     state.error = null;
    },
   updateName:(state, {payload})=>{
    state.adminInfo["name"] = payload;
   },
   updateEmail:(state, {payload})=>{
    state.adminInfo["email"] = payload;
   },
   updateLogo: (state, { payload }) => {
    state.adminInfo["image"] = payload;
  },
},
extraReducers: (builder) => {
    builder.addCase(adminData.pending, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(adminData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.adminInfo = payload?.data?.admin;
      state.adminToken = payload?.data?.token;
      state.adminInfo= payload?.data?.admin;
    });
    builder.addCase(adminData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const {
  logout,
  updateLogo,
  updateName,
  updateEmail,
} = AdminSlice.actions;
export default AdminSlice.reducer;

 
