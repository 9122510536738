import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/logo.svg";
import date from "../../Assets/images/cleandar.png";
import Time from "../../Assets/images/clock.png";
import "./header-admin.module.scss";

function Clock() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000); 
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="d-flex align-items-center">
      <p className="mr-5 m-0 text-dark"><img className="mr-2" src={date} alt="" />{new Date("2024-05-05").toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
      <p className="mr-2 m-0 text-dark"><img className="mr-2" src={Time} alt="" />{time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}</p>
    </div>
  );
}

function HeaderAdmin() {
  const pathname = window.location.pathname;
  const adminInfo = {};

  return (
    <>
      <div className="d-flex">
        <nav className="nav-img navbar navbar-expand navbar-light bg-white topbar static-top">
          <Link className="nav-link logo" to="/dashboard">
            <img src={Logo} alt="Logo" />
          </Link>
        </nav>
        <div className="d-flex justify-content-end ml-3 timer-count">
          <Clock /> 
        </div>
      </div>
    </>
  );
}

export default HeaderAdmin;
