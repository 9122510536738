import React from "react";
import HeaderAdmin from "../../Components/header-admin/HeaderAdmin";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoginImage from "../../Assets/images/login-img.svg"
import IconWave from "../../Assets/images/icon-wave.svg"
import IconFacebook from "../../Assets/images/icon-facebook.svg";
import IconGoogle from "../../Assets/images/icon-google.svg";
import Logo from "../../Assets/images/logo-white.svg";
import { apiBaseUrl } from "../../Components/config/config";
import Joi from "joi";
import { passwordregex} from "../../shared/validators";
import { useState } from "react";
import "./login.admin.scss";

function Login() {
  let navigate = useNavigate();
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [inputType, setInputType] = useState("email");
  const [isEmail, setIsEmail] = useState(true);
  const[error, setError] = useState("");
  const [values, setValues] = useState({
    password: "",
  });
  const [errors, setErrors] = useState({
    password: ""
  });

  const [emailValues, setEmailValues] = useState({
    email: "",
  });
  const schema =Joi.object({
    password: Joi.string().regex(passwordregex).min(8).max(100).required().messages({
      "string.empty": "Password cannot be empty",
      "string.pattern.base": "Please enter the correct format for the password",
      "string.min": "Password must be at least 8 characters long",
      "string.max": "Password must be no more than 100 characters long"
    }),
    
  });
  
  const onChangeInputType = (e) => {
    setError("");
    let value = e.target.value;
    setEmailValues({
      ...emailValues,
      email: value,
    });
  };

  const handleInputOnChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    return emailRegex.test(email);
  }
  const formValidation = () => {
    const result = schema.validate(values);
    if (!result.error) return null;

    let errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formErrors = formValidation();
    if (formErrors) {
      setErrors(formErrors);
      setLoginError(true);
    } else {
      setErrors({});
      let { password } = values;
      let { email } = emailValues;
      if (validateEmail(email)) {
        logIn({
          email,
          password,
        });
      } else {
        setError("Invalid email address");
      }
    }
  };
  const logIn = async (user) => {
    try {
      const res = await axios.post(`${apiBaseUrl}/api/admin/signin`, { ...user });
      if (res.data.meta.statusCode === 200) {
          sessionStorage.setItem("token", res.data.data.token);
          sessionStorage.setItem("email", res.data.data.email);
          sessionStorage.setItem("password", res.data.data.password);
          navigate('/otp');
      }
    } catch (error) {
      setLoginError(true);
      setLoginErrorMessage("Invalid credentials");
      setTimeout(() => {
        setLoginError(false);
        setLoginErrorMessage("");
      }, 5000);
    }
  };
  
  return (
    <>
   <div id="login">
      <div className="girl-image">
      <div className="login-container">
        <Link to="/" className="header-logo">
          <img src={Logo} alt="Logo" />
        </Link>
        <div className="d-flex full-sec flex-xl-row flex-lg-row flex-md-row flex-sm-column  justify-content-between">
          <div className="left-sec">
            <div className="heading-login">Amazing day.</div>
            <div className="description-login mt-3">
              Maybe some text here will help me see it better. Oh God. Oke,
              let’s do it then.
            </div>
          </div>
          
          <div className="log">
           <img className="login-image-section"
              src={LoginImage}
              alt="LoginImage"
            />
          </div>
 
        <div className="mb-5 right-sec form-wid">
            <div className="login-form   mr-5" >
              <img src={IconWave} alt="IconCancel" />
              <div className="mt-2">
                <div className="heading-form">Welcome back!</div>
                <div className="text-para mt-2">Please Login to Continue</div>
                {loginError && (
                  <div
                    className="error-text text-center alert-danger"
                    role="alert"
                  >
                    {loginErrorMessage}
                  </div>
                )}
              </div>
              <form onSubmit={handleOnSubmit}>
                <div className="mt-4">
                  <div className="field-heading">E-mail or phone number</div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Type your e-mail or phone number"
                      name={inputType}
                      // type={inputType}
                      onChange={(e) => onChangeInputType(e)}
                      required
                    />
                  </div>
                  {errors[inputType] && (
                    <div className="error-text pb-1">
                      {errors[inputType]}
                     </div>
                    )}
                  {error && (
                    <div className="error-text pb-1">
                      {error}
                     </div>
                    )}
                </div>

                <div className="mt-2">
                  <div className="field-heading">Password</div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="********"
                      name="password"
                      value={values.password}
                      onChange={handleInputOnChange}
                      required
                    />
                  </div>
                  {errors.password && (
                    <div className="error-text pb-1">
                      {errors.password} 
                    </div>
                  )}
                </div>
                <div className="forget-text">
                  <Link to="/forgot-password">Forget Password ?</Link>
                </div>
                <div className="mt-2">
                  <button type="submit" className="btn-sign-in w-100">
                    Sign In
                  </button>
                </div>
              </form>
              <div className="d-flex justify-content-center align-items-center py-2">
                <span className="line-login"></span>
                <small className="px-2 line-text">
                  or do it via other accounts
                </small>
                <span className="line-login"></span>
              </div>
              <div className="d-flex justify-content-center align-items-center py-3">
                <div role="button" className="icon-card mx-3">
                  <img src={IconGoogle} alt="IconGoogle" />
                </div>
                <div role="button" className="icon-card mx-3">
                  <img src={IconFacebook} alt="IconFacebook" />
                </div>
              </div>
              <div className="mt-3">
                <div className="login-footer mx-1">
                  Don’t have an account?{" "}
                  <span className="login-footer-span">
                    <Link to="/register">Get started</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    </>
  );
}

export default Login;
