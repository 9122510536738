import "./user-account-detail.scss";
import React, { useEffect, useState } from "react";
import refresh from "../../Assets/images/user-profile.png"
import axios from "axios";
import { useSelector } from "react-redux";
import { apiBaseUrl, imgBaseUrl } from "../../Components/config/config";
import { useParams } from "react-router-dom";
const UserAccountDetail = () => {

  const [userData, setUserData] = useState({});
  const { userId } = useParams();
const{ adminToken, adminInfo } = useSelector((state) => state.admin);
  useEffect(() => {
      const config = {
          headers: {
              "x-auth-token": adminToken,
          },
      };
      axios.get(`${apiBaseUrl}/api/admin/user-detail-info/${userId}`, config).then((res) => {
          if (res.data.meta.statusCode === 200) {
              setUserData(res.data.data.user);
          }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="uni_padding d-flex justify-content-between align-items-center mt-3">
        <h1 className="heading-main">User Detail</h1>
      </div>
      <div className="dashboard-card">
        <div className="cover-img"></div>
        <div className="pl-3 pr-3">
          <div className="d-flex user-img-top">
            {adminInfo.image?
            <div>
              <img src={`${imgBaseUrl}/profile/avatar_${userData.image}`}  alt="" />
            </div>:<div>
              <img src={refresh} alt="" />
            </div>}

            <div className="d-flex justify-content-between w-100 align-items-end">
              <div className="ml-2 user_details">
                <h1>{userData.name}</h1>
              <p>{userData.email}</p>
              </div>
        
            </div>
          </div>
        </div>
        <div className="more-details d-flex justify-content-between mt-5 mr-5">
            <div>
                <div><p>Phone</p></div>
               <div><b>{userData.contact?userData.contact:"No contact"}</b></div>
            </div>
            {userData?.address?.map((data)=>(
                <>
            <div>
            <div><p>Province</p></div>
               <div><b>{data?.province}</b></div>

            </div>
            <div>
            <div><p>City</p></div>
               <div><b>{data?.city}</b></div>
            </div>
            <div>
            <div><p>Area</p></div>
               <div><b>{data.landmark}</b></div>
            </div>
            </>
            ))}
        </div>
        <div className="mt-5">
        <p>Detail Address</p>
        {userData?.address?.map((data)=>(
            <b>{data.completeAddress}</b>
        ))}
         </div>
      </div>
    
    </>
  )
}
export default UserAccountDetail;