import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import refresh from "../../Assets/images/refresh.png";
import users from "../../Assets/images/users.png";
import sellers from "../../Assets/images/sellers.png";
import blocked from "../../Assets/images/blocked.png";
import earning from "../../Assets/images/Total-Earning.png";
import axios from "axios"
import { apiBaseUrl } from "../../Components/config/config";
import { useSelector } from "react-redux";


const  Dashboard= () =>{
  const [data, setData] = useState("");
  const {adminToken} = useSelector((state)=> state.admin);

  useEffect(() => {
    const config = {
      headers: {
        "x-auth-token": adminToken,
      },
    }
    const apiUrl = `${apiBaseUrl}/api/admin/dashbaord-status-total`;
    axios.get(apiUrl, config).then((res) => {
      if(res.data.meta.status) {
         setData(res.data);
      }
   }).catch((error)=>{
     
   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
    <div className="uni_padding d-flex justify-content-between align-items-center mt-3">
      <h1 className="heading-main">Dashboard</h1>
      <p className="m-0">Last updated 4/23/2024  <button className="btn"><img src={refresh} alt="" /></button></p>
    </div>

    <div className="mini_cards d-flex flex-wrap">
      <div className="mini_card">
       <div className="d-flex justify-content-between">
       <h6>Total Users</h6>
        <p className="ratio">+5.09%</p>
       </div>
       <div className="d-flex">
        <img src={users} width={70} alt="logo" />
        <b className="amount">{data.totalUser}</b>
       </div>
      </div>
      <div className="slach"></div>
      <div className="mini_card">
       <div className="d-flex justify-content-between">
       <h6>Total Seller</h6>
        <p className="ratio">+1.23%</p>
       </div>
       <div className="d-flex">
        <img src={sellers} width={70} alt="logo" />
        <b className="amount">{data.totalSeller}</b>
       </div>
      </div>
      <div className="slach"></div>
      <div className="mini_card">
       <div className="d-flex justify-content-between">
       <h6>Blocked</h6>
        <p className="ratio">-1.07%</p>
       </div>
       <div className="d-flex">
        <img src={blocked} width={70} alt="logo" />
        <b className="amount">{data.blockedUser + data.blockedSeller}</b>
       </div>
      </div>
    </div>

    <div className="dashboard-card">
   <img className="w-100" src={earning} alt="" />
    </div>
    </>
  );
}
export default Dashboard;
