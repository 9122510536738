import React, { Component } from 'react'
import "./configuration.scss";
import { apiBaseUrl } from  "../../Components/config/config"
import {useSelector } from "react-redux";
import axios from "axios";
import { toast,ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { error } from 'jquery';

const Configuration = ()=>{
  const { adminToken } = useSelector((state) => state.admin);
  const config = {
    headers: {
        "x-auth-token": adminToken,
    },
};

const checkElasticHealth = () => {
  axios.get(`${apiBaseUrl}/api/admin/checkElasticHealth`, config)
    .then(response => {
      if (response.data.meta.status) {
        toast.success("Elastic health status retrieved successfully");
      } else {
        toast.error("Failed to retrieve Elastic health status");
      }
    })
    .catch(error => {
      toast.error("Error checking Elastic health");
    });
};
const SyncTitle = () => {
  axios.post(`${apiBaseUrl}/api/admin/sync-title`, config)
    .then(response => {
      if (response.status === 200) {
        toast.success("Titles are syncing with MongoDB");
      } else {
        toast.error("Error in syncing titles with MongoDB");
      }
    })
    .catch(error => {
      toast.error("Error in syncing titles with MongoDB");
    });
};

const syncElastic = () => {
     axios.post(`${apiBaseUrl}/api/admin/syncElastic`, config)
        .then(response => {
            if (response.data.meta.status){
              toast.success("Elastic is syncing");
            }
        })
        .catch(error => {
          toast.error("error in syncing");
        });
};
return(
<>
  <div className="uni_padding d-flex justify-content-between align-items-center mt-3">
  <div className="dashboard-cards">
    <div className="d-flex justify-content-between">
    <button type="button" class="btn btn-primary" onClick = {syncElastic}>SyncElastic</button>
    <button type="button" class="btn btn-primary" onClick = {checkElasticHealth }>Check-elastic-Health</button>
    <button type="button" class="btn btn-primary" onClick = {SyncTitle }>Sync-title-mongodb</button>
    </div>
</div>
</div>
<ToastContainer />
</>)
}
export default Configuration;