import React, { useEffect, useState } from "react";
import "./user-account.scss";
import searchIcon from "../../Assets/images/icon-search.svg"
import { apiBaseUrl, imgBaseUrl } from "../../Components/config/config";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import actions from "../../Assets/images/icons/actions.svg";
import viewIcon from "../../Assets/images/icons/view-icon.svg";
import deleteicon from "../../Assets/images/icons/delete-icon.svg";
import warning from "../../Assets/images/icons/warning.svg";
import disable from "../../Assets/images/icons/disable-icon.svg";
import { Link } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import ConfirmModal from "../../Components/modals/confirm-modal/ConfirmModal";

function UserAccounts() {
  const [pageSize, setPageSize] = useState(6);
  const [page, setPage] = useState(0);
  const [userListing, setUserListing] = useState([]);
  const { adminToken } = useSelector((state) => state.admin);
  const [openModel, setOpenModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [status, setStatus] = useState("");
  const [callApi, setCallApi] = useState(false);
  const [searchQuery,  setSearchQuery]= useState("");

  const confirmChangeStatus = (id, userStatus) => {
    setOpenModal(true);
    setUserId(id);
    setStatus(userStatus);
  }
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    if (searchQuery.length >= 3 || searchQuery === "") {
    const config = {
      headers: {
        "x-auth-token": adminToken,
      },
    };
    const apiUrl = `${apiBaseUrl}/api/admin/users-listing?pageSize=${pageSize}&page=${page}&searchQuery=${searchQuery}`;
    axios.get(apiUrl, config)
      .then((res) => {
        if (res.data.meta.status) {
          setUserListing(res.data.data.userListing);
        } else {
          setUserListing(res.data.data.userListing);
        }
      })
      .catch((error) => {
        // Handle error
      });}
  }, [pageSize, page, searchQuery]);

  const handleModalClose = () => {
    $("#order-modal").modal("hide");
  };
  useEffect(() => {
    changeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callApi]);

  const changeStatus = () => {
    const config = {
      headers: {
        "x-auth-token": adminToken,
      },
    };
    if (userId && status) {
      axios.put(`${apiBaseUrl}/api/admin/update-user-status`, {
        userId,
        status,
      }, config)
        .then((res) => {
          if (res.data.meta.status) {
           setCallApi(false);
            handleModalClose();
            setUserListing((prevUserListing) => {
              const updatedListing = prevUserListing.map((user) => {
                if (user._id === res.data?.data?.userData?._id) {
                  return res.data?.data?.userData;
                }
                return user;
              });
              return updatedListing;
            });
          }
        })
        .catch((error) => {
        });
    } else {
      toast.error("your Id is missing")
    }
  }

  return (
    <>
      <div className="uni_padding d-flex justify-content-between align-items-center mt-3">
        <h1 className="heading-main">User Accounts</h1>
        {/* <p className="m-0">Last updated 4/23/2024  <button className="btn"><img src={refresh} alt="" /></button></p> */}
      </div>
      <div className="dashboard-card">
        <div className="d-flex justify-content-between">
          {/* <p className="text-dark">Total List: 21 new</p> */}
          <div className="d-flex align-items-center"> <span className="mr-2 text-muted">Show</span>
            <select className="select_entries" onChange={(e) => { setPageSize(e.target.value) }}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
            </select>
            <span className="ml-2 text-muted">entries</span>
          </div>
          <div className="d-flex search-bar" id="navbarSupportedContent">
            <div type="button" className="search-button text-center m-1">
              <img src={searchIcon} alt="filter icon" /> </div>
            <input className="search-input" placeholder="Search"  type="text"
             value={searchQuery}
             onChange={handleSearchChange} />
          </div>
        </div>
        <div>
          <table className="table table-hover mt-3">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Contact</th>
                <th>Status</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {userListing.map((val, index) => {
                return (<tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <div className="d-flex">
                      <div>
                        <img className="table_img" src={`${imgBaseUrl}/profile/avatar_${val.image}`} alt="" />
                      </div>
                      <div className="ml-2">
                        <p className="m-0 text-dark">{val.name}</p>
                        <p className="m-0">{moment(val.createdAt).format(
                          "DD  MMMM  YYYY hh:mm:ss A"
                        )}{" "}</p>
                      </div>
                    </div>
                  </td>
                  <td>{val?.email}</td>
                  <td>{val.contact}</td>
                  <td><button className="active_tbl_btn">{val.status}</button></td>
                  <td>{val?.address[0]?.city}</td>
                  <td>
                    <div class="dropdown">
                      <img src={actions} alt="Actions" type="button" data-toggle="dropdown" aria-expanded="false" />
                      <div class="dropdown-menu">
                        <ul>
                          <Link style = {{"text-decoration":"none"}}to={`/user-account/${val._id}`}>
                            <li>
                              <div className="ml-2">
                                <img src={viewIcon} alt="View" />
                                <span className="ml-2 text-decoration">View</span>
                              </div>
                            </li>
                          </Link>
                          {val.status === "Warned" ? (<></>) : (<>
                            <li>
                              <div className="ml-2 mt-2" onClick={() => confirmChangeStatus(val._id, "Warned")} data-toggle="modal" data-target="#order-modal">
                                <img src={warning} alt="Warning" />
                                <span className="ml-2 text-decoration">Warning</span>
                              </div>

                            </li>
                          </>)}
                          {val.status === "Blocked" ? (<></>) : (<>
                            <li>
                              <div className="ml-2 mt-2" onClick={() => confirmChangeStatus(val._id, "Blocked")} data-toggle="modal" data-target="#order-modal">
                                <img src={disable} alt="Disable" />
                                <span className="ml-2 text-decoration">Block</span>
                              </div>
                            </li>
                          </>)}
                          {val.status === "Deleted" ? (<></>) : (<>
                            <li>
                              <div className="ml-2 mt-2" onClick={() => confirmChangeStatus(val._id, "Deleted")} data-toggle="modal" data-target="#order-modal">
                                <img src={deleteicon} alt="Delete" />
                                <span className="ml-2 text-decoration">Delete</span>
                              </div>
                            </li>
                          </>)}
                          {val.status === "Active" ? (<></>) : (<>
                            <li>
                              <div className="ml-2 mt-2" onClick={() => confirmChangeStatus(val._id, "Active")} data-toggle="modal" data-target="#order-modal">
                                <img src={deleteicon} alt="Active" />
                                <span className="ml-2 text-decoration">Active</span>
                              </div>
                            </li>
                          </>)}
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>)
              })}
            </tbody>

          </table>
          <div className="d-flex justify-content-between">
            <p>Showing <span>1</span> to <span>10</span> of <span>198</span> entries</p>
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item" onClick={() => setPage(page - 1)}>
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                {[1, 2, 3, 4, 5]?.map((val, index) => {
                  return (page === index ? <li class="page-item active" onClick={() => setPage(index)}><a class="page-link" href="#">{index + 1}</a></li> : <li class="page-item" onClick={() => setPage(index)}><a class="page-link" href="#">{index + 1}</a></li>)
                })}
                <li class="page-item" onClick={() => setPage(page + 1)}>
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {openModel && (
        <ConfirmModal
          message={
            status === "Deleted"
              ? "Are you sure you want to delete?"
              : status === "Warned"
                ? "Are you sure you want to warn?"
                : status === "Blocked"
                  ? "Are you sure you want to block?"
                  : status === "Active"
                    ? "Are you sure you want to Active"
                    : ""
          }
          setCallApi={setCallApi}
        />
      )}
    </>
  );
}

export default UserAccounts;
