import './App.css'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/login/Login";
import Dashboard from './Pages/dashboard/Dashboard';
import ListingReview from './Pages/listing-review/ListingReview';
import SellerAccounts from './Pages/seller-accounts/SellerAccounts';
import UserAccounts from './Pages/user-accounts/UserAccounts';
import UserAccountDetail from './Pages/user-account-detail/UserAccountDetail';
import Reviews from './Pages/reviews/Reviews';
import Reports from './Pages/reports/Reports';
import ForgetPassword from './Pages/forget-password/ForgetPassword';
import PageLayoutAdmin from "./Components/pages-layout-admin/PageLayoutAdmin";
import ProtectedRoutes from "./Components/protected-routes/ProtectedRoutes";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Otp from "../src/Pages/otp/Otp";
import Configuration from './Pages/configuration/Configuration';
import Setting from './Pages/dashboard/setting/Setting';
import VerifyToken from './Pages/verify-token/VerifyToken';
import PublicRoute from './Components/public-routes/PublicRoute';
import SellerAccountDetail from './Pages/seller-account-detail/SellerAccountDetail';
import SellerAccountStoreView from './Pages/seller-account-store-view/SellerAccountStoreView';
function App() {
  return (
    <Router>
        <div>
          <Routes>
          <Route exact path="/verify-token" element={<VerifyToken/>} />
            <Route exact path="/" element={<Login/>} />
            <Route exact path="/login" element={<Login/>} />
            <Route  path="/forgot-password" element={<ForgetPassword/>} />
               <Route
               exect
               path="/otp"
               element={<PublicRoute component={Otp} />}
             />
            <Route
              exect
              path="/dashboard"
              element={
                <PageLayoutAdmin>
                  <ProtectedRoutes component={Dashboard} />
                </PageLayoutAdmin>
              }
            />

            <Route
              exect
              path="/setting"
              element={
                <PageLayoutAdmin>
                  <ProtectedRoutes component={Setting} />
                </PageLayoutAdmin>
              }
            />
             <Route
              exect
              path="/config"
              element={
                <PageLayoutAdmin>
                  <ProtectedRoutes component={Configuration} />
                </PageLayoutAdmin>
              }
            />
            <Route exact path="/listing-review" element={
                <PageLayoutAdmin>
                  <ProtectedRoutes component={ListingReview} />
                </PageLayoutAdmin>} />
            <Route exact path="/seller-accounts" element={
                <PageLayoutAdmin>
                  <ProtectedRoutes component={SellerAccounts} />
                </PageLayoutAdmin>} />
            <Route exact path="/seller-account/:storeId" element={
                <PageLayoutAdmin>
                  <ProtectedRoutes component={SellerAccountDetail} />
                </PageLayoutAdmin>} />
            <Route exact path="/seller-accounts/:storeId/store-view" element={
                <PageLayoutAdmin>
                  <ProtectedRoutes component={SellerAccountStoreView} />
                </PageLayoutAdmin>} />
            <Route exact path="/user-accounts" element={
              <PageLayoutAdmin>
                <ProtectedRoutes component={UserAccounts} />
              </PageLayoutAdmin>
            } />
            <Route exact path="/user-account/:userId" element={
              <PageLayoutAdmin>
                <ProtectedRoutes component={UserAccountDetail} />
              </PageLayoutAdmin>
            } />
            <Route exact path="/reviews" element={
                <PageLayoutAdmin>
                  <ProtectedRoutes component={Reviews} />
                </PageLayoutAdmin>} />
            <Route exact path="/reports" element={
              <PageLayoutAdmin>
                <ProtectedRoutes component={Reports} />
              </PageLayoutAdmin>
            } />
          </Routes>
        </div>
      </Router>
  );
}

export default App;
