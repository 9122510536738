import React, { useEffect, useState } from "react";
import searchIcon from "../../Assets/images/icon-search.svg"
import refresh from "../../Assets/images/refresh.png";
import userphoto from "../../Assets/images/listing/user-photo.png";
import "./listing-review.scss";
import { apiBaseUrl, imgBaseUrl } from "../../Components/config/config";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import LoadMore from "../../Assets/images/icon-load-more.svg";
import { toast } from "react-toastify";
import ConfirmModal from "../../Components/modals/confirm-modal/ConfirmModal";
import ListingDetailModal from "../../Components/modals/listing-detail/ListingDetailModal";
import $ from "jquery";

function ListingReview() {
  const [statusValue, setStatuValue] = useState("pending");
  const [listing, setListing] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const [loadData, setLoadData] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const [listingId, setListingId] = useState(0);
  const [status, setStatus] = useState("");
  const [listId, setListId] = useState("");
  const [searchQuery,  setSearchQuery]= useState("");
  const { adminToken } = useSelector((state) => state.admin);
  
const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    if (searchQuery.length >= 3 || searchQuery === ""){
    const config = {
      headers: {
        "x-auth-token": adminToken,
      },
    };
    const apiUrl = `${apiBaseUrl}/api/admin/pending-listing/${statusValue}?page=${page}&searchQuery=${searchQuery}`;
    axios.get(apiUrl, config)
      .then((res) => {
        if (res.data.meta.status) {
          // setListing([...listing, ...res.data.data.listing]);
          setListing(res.data.data.listing)
        }
        if (res.data.meta.page === page) {
          setIsLoad(false);
        } else {
          setPage(res.data.meta.page);
        }
      })
      .catch((error) => {
        // Handle error
      });
  }}, [statusValue, loadData ,searchQuery]);

  const confirmChangeStatus = (id, listingStatus) => {
    setOpenModal(true);
    setListingId(id);
    setStatus(listingStatus);
  }
  const handleModalClose = () => {
    $("#order-modal").modal("hide");
  };
  useEffect(() => {
    changeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callApi]);
  const changeStatus = () => {
    const config = {
      headers: {
        "x-auth-token": adminToken,
      },
    };
    if (listingId && status) {
      axios.put(`${apiBaseUrl}/api/admin/update-listing-status`, {
        listingId,
        status,
      }, config)
        .then((res) => {
          if (res.data.meta.status) {
            const updatedData = listing.filter(data =>
              data?.itemReviews._id !== res.data.data?.listing._id
            )
            setListing(updatedData);
            handleModalClose();
          }
        })
        .catch((error) => {
        });
    } else {
      toast.error("your Id is missing")
    }
  }
  return (
    <>
      <div className="uni_padding d-flex justify-content-between align-items-center mt-3">
        <h1 className="heading-main">Listing</h1>
        <p className="m-0">Last updated 4/23/2024  <button className="btn m-0 p-0"><img src={refresh} alt="" /></button></p>
      </div>

      <div className="dashboard-card">
        <div className="d-flex justify-content-between mb-2">
          <p className="text-dark">Total List: 21 new</p>
          <div>
            <select className="active-status" value={statusValue}
              onChange={(e) => { setListing([]); setPage(0); setStatuValue(e.target.value) }}
            >
              <option value='pending'>Pending</option>
              <option value='Active'>Active</option>
              <option value='rejected'>Rejected</option>
              <option value='blocked'>Blocked</option>
              <option value='warned'>Warned</option>
            </select>
          </div>
          <div className="d-flex search-bar" id="navbarSupportedContent">
            <div type="button" className="search-button text-center m-1">
              <img src={searchIcon} alt="filter icon" /> </div>
            <input className="search-input" placeholder="Search" 
                type="text"
                value={searchQuery}
                onChange={handleSearchChange} />
          </div>
        </div>
        <div className="card-rap">
          {listing?.map((data, index) => (
            <>
              <div className="review-card mt-3">
                <div className="l-side">
                  <div className="d-flex">
                    <img className="user-photo" src={userphoto} alt="user-photo" />
                    <div className="user-details">
                      <h1>{data?.storeInfo?.storeName}</h1>
                      <p>{data?.storeInfo?.email}</p>
                      <p>#Ph: <span>0348 3213218</span></p>
                      <p>ID:{data?.itemReviews?.storeId} <span></span></p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between flex-wrap user-details m-0">
                    {data?.storeInfo?.address?.slice(0, 1).map((details) => (
                      <p>
                        Address:
                        <span>
                          Province: {details?.province}{' '}
                          City: {details?.city}{' '}
                          Location: {details.location}{' '}
                          Store Address: {details?.storeAddress}{' '}
                          Postal or Zip: {details?.postalOrZip}
                        </span>
                      </p>
                    ))}
                    <button className="btn approve-btn mt-3">Store Detail</button>
                  </div>
                </div>
                <div className="r-side">
                  <div className="m-0 user-details d-flex justify-content-between w-50 flex-wrap">
                    <b>{data.itemReviews.title}</b>
                    <p className="m-0"> {moment(data?.itemReviews?.createdAt).format(
                      "DD  MMMM  YYYY hh:mm:ss A"
                    )}{" "}</p>
                    {/* <p>Order ID: <span> 2319732937</span></p>
   <p>Order Placed:<span> 22 Jan 2024 23:22:18</span></p> */}
                  </div>
                  <div className="description mt-2">
                    {/* <textarea className="text-dark" readOnly rows={showFullDescription ? "5" : "1"} style={{ border: "none", resize: "none", width: "100%" }}>
{showFullDescription ? data.itemReviews.description : data.itemReviews.description.slice(0, 110)}
</textarea>
{data.itemReviews.description.length > 110 &&
<span className="text-warning" onClick={() => setShowFullDescription(!showFullDescription)}>
  {showFullDescription ? '... see less' : '... see more'}
</span>
} */}
                    <div className="about-product" dangerouslySetInnerHTML={{ __html: data.itemReviews.description, }}>
                    </div>
                  </div>
                  <div className="review-imgs">
                    {data.itemReviews.images.map((image, imgIndex) => (
                      <img key={imgIndex} className="user-photo mr-3" src={`${imgBaseUrl}/listing/small_${image}`} alt={`user-photo-${imgIndex}`} />
                    ))}
                  </div>
                  {data.itemReviews.status !== "Active" ? (
                  <div className="mt-3">
                    <button className="success-btn" onClick={() => confirmChangeStatus(data?.itemReviews._id, "Active")} data-toggle="modal"
                      data-target="#order-modal">Accept</button>
                    <button className="danger-btn" onClick={() => confirmChangeStatus(data?.itemReviews._id, "Rejected")} data-toggle="modal"
                      data-target="#order-modal">Reject</button>
                    <button className="warning-btn" data-toggle="modal"
                     data-target="#listing-modal" onClick={()=>setListId(data?.itemReviews._id)}>View Detail</button>
                  </div>
                  ):(<></>)}
                </div>

              </div>

              <hr /></>
          ))
          }
          {isLoad ?
            <>
              <div
                className="d-flex justify-content-center heading-more mt-3"
                role="button"
                onClick={() => setLoadData(!loadData)}
              >
                Load More
                <img className="ml-2" src={LoadMore} alt="LoadMore" />
              </div>
            </>
            :
            <></>
          }
        </div>
      </div>
      {openModal && (
        <ConfirmModal
        message={status === "Rejected" ? "Are you sure you want to Reject?" : "Are you sure you want to publish?"}
          setCallApi={setCallApi}
        />
      )}
      <ListingDetailModal id ={listId}/>
    </>
  );
}

export default ListingReview;
