import React, { useState } from "react";
import { Link } from "react-router-dom";
import IconCancel from "../../../Assets/images/icon-cancel.svg"
import { useSelector } from "react-redux";
import axios from "axios";
import starfilled from "../../../Assets/images/icon-star-filled.svg"
import $ from "jquery";
import { apiBaseUrl, imgBaseUrl } from "../../config/config";
import { useEffect } from "react";
import "./listingDetailModal.scss";

const ListingDetailModal = ({ id }) => {
  const [listingData, setListingData] = useState({});
  const [error, setError] = useState("");
  const [colorIndex, setColorIndex] = useState(0);
  const { adminToken } = useSelector((state) => state.admin);
  const [imgIndex, setImageIndex] = useState(0);
  const [subPropertyType, setSubPropertyType] = useState("");
  const [qtyLimit, setQtyLimit] = useState(0);
  const [subPropertyColor, setSubPropertyColor] = useState("");
  const [num, setNum] = useState(0);
  useEffect(() => {
    const config = {
      headers: {
        "x-auth-token": adminToken,
      },
    };
    axios.get(`${apiBaseUrl}/api/admin/product/${id}`, config)
      .then(response => {
        if (response.status === 200) {
          setListingData(response.data);
        } else {
          setError(response.meta.message);
        }

      })
      .catch(error => {
        console.error('Error fetching product:', error);
        setError('An error occurred while fetching the product.');
      });
  }, [id]);

  const handleColorIndex = (index, color) => {
    setColorIndex(index);
    setSubPropertyColor(color);
    setNum(1);
  };
  const handleSubPropertySize = (subQuantity, subType) => {
    setQtyLimit(subQuantity);
    setSubPropertyType(subType);
    setNum(1);
  };
  return (
    <div
      className="modal"
      id="listing-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="listingModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="listing-modal">
          <div className="modal-content border-0">
            <div className="padding-all">
              <div className="modal-data d-flex flex-row justify-content-between">
                <div className="images">
                  {listingData?.data?.listing?.images ?
                    <>
                      <div className="cover-image"><img src={`${imgBaseUrl}/listing/medium_${listingData?.data.listing.images[imgIndex]}`} className="top-image"></img></div>
                    </> : <></>}

                  <div className="list-images d-flex flex-row mt-2 ">
                    {listingData?.data?.listing?.images.slice(0, 4).map((image, index) => (
                      <>
                        <img src={`${imgBaseUrl}/listing/medium_${image}`} className="image-list-size" onClick={() => setImageIndex(index)}></img>
                      </>
                    ))}
                  </div>

                </div>
                <div className="further-details ">
                  <div className="title ">{listingData?.data?.listing?.title}</div>
                  <div className="rating mt-2">
                    <img src={starfilled}></img>
                    <img src={starfilled}></img>
                    <img src={starfilled}></img>
                    <img src={starfilled}></img>
                    <img src={starfilled}></img>
                    <b> 4.3 reviews </b><span>(100 sold)</span>

                  </div>
                  <div className="store-name mt-2 "><b> store: </b><span><a href="//">{listingData?.data?.storeInfo?.storeName}</a></span></div>
                  <div className="colors mt-2 d-flex flex-row">
                     <div  className="mt-1"><b className="font-sizing">colors: </b></div>
                       <div className="colors">
                      {listingData?.data?.listing && listingData?.data?.listing?.properties ? (
                        JSON.parse(listingData?.data?.listing?.properties).map(
                          (color, index) => (
                            <button
                              key={index}
                             className="color-size"
                              style={{
                                background: `${color.color}`,
                              }}
                              onClick={() =>
                                handleColorIndex(index, color.color)
                              }
                            ></button>
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </div>
               
                  </div>
                  <div>
                    {colorIndex >= 0 ? (
                      <>
                      <div className="d-flex flex-row mt-2">
                        <div className="mt-1 d-flex justify-content-between align-items-center ">
                          <span className="text-store">
                            Sizes
                          </span>
                        </div>
                        <div className="">
                          {listingData?.data?.listing?.properties && (
                            JSON.parse(listingData?.data?.listing?.properties).map((properties, indexI) => (
                              <div key={indexI} className="d-flex flex-row">
                                {colorIndex === indexI && properties["subProperty"] && (
                                  <>
                                    {properties["subProperty"].map((subProperty, indexJ) => (
                                      <div className="mr-2" key={indexJ}>
                                        <button
                                          className={subPropertyType === subProperty.type ? "btn-size-active" : "btn-size"}
                                          type="button"
                                          onClick={() => handleSubPropertySize(subProperty.quantity, subProperty.type)}
                                        >
                                          <div className="text-sizes">{subProperty.type}</div>
                                        </button>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </div>
                            ))
                          )}
                        </div>
                       
                        </div>
                        <div className="mt-2 d-flex justify-content-between align-items-center ">
                          <span className="text-store">Quantity <span> {qtyLimit}</span></span>
                        </div>
                      </>
                      
                    ) : (
                      <>please select color first</>
                    )}
                  </div>
                </div>
                <div className="modal-header border-0">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <img src={IconCancel} alt="IconCancel" />
                    </span>
                  </button>
                </div>
              </div>
              <div className="mt-4 description">
                <b>The details of my product are given below check it</b>
                <div dangerouslySetInnerHTML={{ __html: listingData?.data?.listing.description, }}>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingDetailModal;
